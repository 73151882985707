.view-payment-method {
  position: relative;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-and-create-new-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.create-new-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header-and-create-new-button>label {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color-main);
}

.create-new-button label {
  font-size: 16px;
  font-weight: 800;
  color: var(--green);
}

.empty-payment-method {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  overflow: hidden;
}

.empty-payment-method p {
  width: 250px;
  font-size: 20px;
  font-weight: 500;
  color: #7d7d7d;
  text-align: center;
}

.card-payment-method {
  border: var(--green) 2px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 18px;
  padding: 10px 30px 10px 10px;
  margin-top: 20px;
}

.payment-card-data {
  display: flex;
  align-items: flex-start;
  gap: 0px 15px;
  color: var(--text-color-main);
}

.card-number-and-expiration-date {
  display: flex;
  gap: 10px;
}

.card-payment-method button {
  background-color: var(--green);
  color: var(--text-color-main);
  font-size: 18px;
  font-weight: 800;
  border: none;
  padding: 10px;
  border-radius: 18px;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .view-payment-method {
    display: none;
  }

  .view-mobile-payment-method {
    text-align: center;
    padding: 30px;
  }

  .empty-payment-method-on-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 100px 0px;
  }

  .empty-payment-method-on-mobile p {
    width: 250px;
    font-size: 20px;
    font-weight: 500;
    color: #7d7d7d;
    text-align: center;
  }

  .mobile-card-payment-method {
    border: var(--green) 2px solid;
    border-radius: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
    gap: 0px 15px;
    margin: 20px 0px;
    color: var(--text-color-main);
  }

  .mobile-payment-method-information {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .mobile-payment-method-information>label {
    font-size: 20px;
    font-weight: bold;
  }

  .card-icon-containerfor-mobile-payment-method {
    padding-top: 5px;
  }

  .payment-card-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .delete-payment-method-button-on-mobile {
    background-color: var(--green);
    color: var(--text-color-main);
    font-weight: bold;
    padding: 7px;
    border: none;
    border-radius: 12px;
    margin-top: 15px;
    min-width: 100px;
  }

  .mobile-payment-method-card-number-and-expiration-date {
    display: flex;
    gap: 15px;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .view-mobile-payment-method {
    display: none;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .view-mobile-payment-method {
    display: none;
  }
}